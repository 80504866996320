import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'user>update'
	},

	validation: {
		username: {
			required: false,
			validator: null
		},

		password_old: {
			required: false,
			validator: null
		},

		password: {
			required: false,
			validator: null
		},

		password_repeat: {
			required: false,
			validator: null
		}
	},

	gql: {
		schema: gql`
			mutation UpdateUser(
				$password_old: String,
				$password: String,
				$password_repeat: String,
				$username: String
			) {
				request(
					password_old: $password_old,
					password: $password,
					password_repeat: $password_repeat,
					username: $username
				) {
					user_id
				}
			}
		`
	}
};

export default request;
