import gql from 'graphql-tag';

const request = {
	validation: {
		username: {
			required: true,
			validator: null
		},

		password: {
			required: true,
			validator: null
		},

		long_period: {
			required: false,
			validator: null,
			falseIfEmpty: true
		}
	},

	gql: {
		schema: gql`
			mutation Login(
			  $username: String,
			  $password: String,
			  $long_period: String
			) {
			  request(
			    username: $username,
			    password: $password,
			    long_period: $long_period
			  ) {
			    access_token
			  }
			}
		`
	}
};

export default request;
