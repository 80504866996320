import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '../../apollo/lib';
import { useQuery, useMutation } from '@apollo/react-hooks';

import useForm from '../_hooks/useForm';
import { scrollToFirstError } from '../../helpers/form';
import { gqlValidation, setContext } from '../../helpers/gql';

// import Loader from '../loader/loader';
import AsyncComponent from '../_hoc/asyncComponent';

/*
HOW TO:
- create src/forms/<name>.js (settings config: validation, gql schema, ...)
- FormLoader>getFormSettings add case by name
- FormLoader>loadFormQuery add case by name (in case query is needed, to prefill form - ex. on edit)
*/

const FormLoader = React.memo(({ name, id, initialState, placeholders, onSuccess, onError, onPrefillSuccess, onPrefillError, onBeforeSubmitToServer, formParameters }) => {
	const initState = initialState; // reassign, so it can be appended, if needed

	const [isDev] = useState(process.env.NODE_ENV === 'development');

	// General form notification (ex. on success action)
	const [notification, setNotification] = useState(null);

	const fieldsRefs = useRef({});
	const formSettings = useRef(null);
	const formQuery = useRef(null);
	const Component = useRef(null);

	// Form component
	if (Component.current === null) {
		Component.current = AsyncComponent(() => {
			switch (name) {
			case 'auth>register': 							return import('./auth/register');
			case 'auth>login': 									return import('./auth/login');
			case 'auth>forgot-password-init': 	return import('./auth/forgot-password-init');
			case 'auth>forgot-password-reset': 	return import('./auth/forgot-password-reset');
			case 'profile>contact': 						return import('./profile/contact');
			case 'profile>user': 								return import('./profile/user');
			case 'profile>notifications': 			return import('./profile/notifications');
			case 'profile>payments': 						return import('./profile/payments');
			// case 'detail-notifications': 				return import('./detail/notifications');
			case 'auction>mutate': 							return import('./auction/auction');
			default: return null;
			}
		});
	}

	// Form settings
	const loadFormSettings = () => {
		switch (name) {
		case 'auth>register': 								return require('../../requests/mutation/auth/register.js').default;
		case 'auth>login': 										return require('../../requests/mutation/auth/login.js').default;
		case 'auth>forgot-password-init': 		return require('../../requests/mutation/auth/forgot-password-init.js').default;
		case 'auth>forgot-password-reset': 		return require('../../requests/mutation/auth/forgot-password-reset.js').default;
		case 'profile>contact': 							return require('../../requests/mutation/profile/contact.js').default;
		case 'profile>user': 									return require('../../requests/mutation/profile/user.js').default;
		case 'profile>notifications': 				return require('../../requests/mutation/profile/notifications.js').default;
		case 'profile>payments': 							return require('../../requests/mutation/profile/payments.js').default;
		// case 'detail-notifications': 					return require('../../requests/mutation/detail/notifications.js').default;
		case 'auction>mutate': 								return require('../../requests/mutation/auction/mutate.js').default;
		default: return null;
		}
	};

	// Form query
	const loadFormQuery = () => {
		switch (name) {
		case 'auction>mutate': 								return require('../../requests/query/auction/item-prefill.js').default;
		case 'profile>contact': 							return require('../../requests/query/profile/contact.js').default;
		case 'profile>notifications': 				return require('../../requests/query/profile/user.js').default;
		default: 															return require('../../requests/query/void.js').default;
		}
	};

	if (formSettings.current === null) {
		formSettings.current = loadFormSettings();
		formQuery.current = loadFormQuery();

		// Generate refs for all fields, which has validation (to manipulate after submit validation error - ex. scroll to field)
		if (formSettings.current && formSettings.current.validation) {
			Object.keys(formSettings.current.validation).map((fName) => {
				fieldsRefs.current[fName] = React.createRef();
				return true;
			});
		}
	}

	const getFormSettings = (section, key) => {
		if (formSettings.current) {
			const settings = formSettings.current[section] || formSettings.current;
			if (key) return settings[key] || null;
			return settings;
		}

		return null;
	};

	// Form submit handle
	const [submitCnt, incSubmitCnt] = useState(0);

	const onSubmitForm = (state) => {
		if (disabled === false) {
			// Request to server only, if graphql scheme exist
			if (gqlMutation) {
				// For GraphQL debug purposes only!
				if (isDev) {
					console.log(JSON.stringify(state));
				}

				if (onBeforeSubmitToServer) onBeforeSubmitToServer(state);

				incSubmitCnt(submitCnt + 1);
				gqlMutation({
					variables: state,
					context: setContext(getFormSettings('properties', 'name') || name)
				});
			} else {
				// If server request is not set, then just return data to parent
				handleOnSuccess(state);
			}
		} else {
			// Find first error and scroll to field + focus
			scrollToFirstError(state.firstErrorFieldName, fieldsRefs.current);
		}
	};

	const handleOnSuccess = (data) => {
		// Handle callback
		const onSuccessType = typeof onSuccess;

		switch (onSuccessType) {
		default: // function
			onSuccess(data);
			break;

		case 'object': {
			if (onSuccess.action) {
				switch (onSuccess.action) {

				case 'FORM_NOTIFICATION':
					// console.log('Success!! Show form notification!');
					break;

				default:
					// console.log('DO NOT KNOW, WHAT TO DO ;)');
					break;
				}
			}

			break;
		}
		}
	};

	// Load form data (init)
	// const isQuery = (id && formQuery.current.properties.name !== 'void');
	const query = formQuery.current;

	const validationRules = getFormSettings('validation');
	const gqlSchema = getFormSettings('gql', 'schema');

	// Init form handler
	const [isQuery, setIsQuery] = useState(false);
	const [isQueryLoading, setIsQueryLoading] = useState(false);

	useEffect(() => setIsQuery(checkIfQuery()), [id]);
	useEffect(() => setIsQueryLoading(isQuery), [isQuery]);

	const { state, handleFocus, handleOnChange, handleOnSubmit, prepareAPIReadyData, validateForm, findFirstErrorFieldName, setError, updateState, updateChanged, disabled } = useForm(isQuery ? 'update' : 'insert', initState, validationRules, onSubmitForm);

	// Init mutation, if graphql scheme isset
	let gqlMutation = null;
	let loading = false;
	let data = null;
	let error = null;

	if (gqlSchema) {
		[gqlMutation, { loading, data, error }] = useMutation(gqlSchema, { errorPolicy: 'all' });
	}

	// Load form data (run)
	const checkIfQuery = () => (id && formQuery.current.properties.name !== 'void');

	const { loading: queryLoading, data: queryData, error:queryError, refetch } = useQuery(query.gql.schema, {
		variables: formParameters.queryIdFieldName ? { [formParameters.queryIdFieldName]: id } : { id },
		context: { headers: { 'Request-Name': query.properties.name } },
		skip: !checkIfQuery(),
		fetchPolicy: 'network-only' // no cache!
	});

	// In case of server submit - this side effect is triggered
	useEffect(() => {
		const err = gqlValidation(error);
		if (err) {
			scrollToFirstError(setError(err, true), fieldsRefs.current);

			if (onError) {
				onError(err, error);
			}
		} else if (onSuccess && data) {
			// Append server response data to form local state .. specialy useful, when new record is created and user should update form again ..
			if (formParameters.onSuccessResponseToState && formParameters.onSuccessResponseToState === true) {
				updateState(data.request);
			}

			handleOnSuccess(data.request);
		}
		// eslint-disable-next-line
	}, [error, data, submitCnt]); // submitCnt = force change, to trigger handleOnSuccess, even if same data as before was submited!

	// In case of query - prefill form, when data arrives
	useEffect(() => {
		if (isQuery) {
			refetch();

			if (queryLoading === false) {
				setIsQueryLoading(false);

				if (queryData && queryData.request) {
			 		updateState(queryData.request);
			 		if (onPrefillSuccess) onPrefillSuccess(queryData.request);
			 } else if (queryError) {
				 if (onPrefillError) onPrefillError(queryError);
			 }
			}
		}
	}, [id, queryData, queryError, queryLoading]);

	// if (queryLoading === true) return <Loader />;
	return <Component.current input={{ state, placeholders, onChange: handleOnChange, onFocus: handleFocus, onBlur: handleFocus }} fieldsRefs={fieldsRefs.current || {}} handleOnSubmit={handleOnSubmit} validateForm={validateForm} updateState={updateState} findFirstErrorFieldName={findFirstErrorFieldName} prepareAPIReadyData={prepareAPIReadyData} disabled={disabled} loading={loading} isQueryLoading={isQueryLoading} notification={notification} updateChanged={updateChanged} formParameters={formParameters} />;
});

FormLoader.defaultProps = {
	id: null,
	initialState: {},
	placeholders: {},
	formParameters: {},
	onSuccess: null,
	onError: null,
	onPrefillSuccess: null,
	onPrefillError: null
};

FormLoader.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	initialState: PropTypes.shape({}),
	placeholders: PropTypes.shape({}),
	formParameters: PropTypes.shape({}),
	onSuccess: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({})
	]),
	onError: PropTypes.func,
	onPrefillSuccess: PropTypes.func,
	onPrefillError: PropTypes.func
};

export default withApollo({ ssr: true })(FormLoader);
