import gql from 'graphql-tag';

const request = {
	validation: {
		email: {
			required: true,
			validator: {
				regEx: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
				error: 'Invalid email'
			}
		}
	},

	gql: {
		schema: gql`
			mutation ForgotPasswordInit(
			  $email: String
			) {
			  request(
			    email: $email
			  ) {
			    user_id
			  }
			}
		`
	}
};

export default request;
