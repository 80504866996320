import gql from 'graphql-tag';

const request = {
	validation: {
		password: {
			required: true,
			validator: null
		},

		password_repeat: {
			required: true,
			validator: null
		},

		hash: {
			required: true,
			validator: null
		}
	},

	gql: {
		schema: gql`
			mutation ForgotPasswordReset(
			  $password: String,
				$password_repeat: String,
				$hash: String
			) {
			  request(
					password: $password,
					password_repeat: $password_repeat,
					hash: $hash
			  ) {
			    username
			  }
			}
		`
	}
};

export default request;
