import gql from 'graphql-tag';

const request = {
	validation: {
		username: {
			required: true,
			validator: null
		},

		password: {
			required: true,
			validator: null
		},

		givenname: {
			required: true,
			validator: null
		},

		surname: {
			required: true,
			validator: null
		},

		email: {
			required: true,
			validator: {
				regEx: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
				// error: 'Invalid email'
				tId: 'validation_error_email'
			}
		},

		agreement_terms: {
			required: true,
			validator: null
		},

		// OAuth
		oauth_provider: {
			required: false,
			validator: null
		},

		oauth_id: {
			required: false,
			validator: null
		}
	},

	gql: {
		schema: gql`
			mutation Register(
				$username: String,
				$password: String!,
				$givenname: String,
				$surname: String,
				$email: String,
				$agreement_terms: Boolean,
				$oauth_id: String,
				$oauth_provider: String,
			) {
				request(
					username: $username,
					password: $password,
					givenname: $givenname,
					surname: $surname,
					email: $email,
					agreement_terms: $agreement_terms,
					oauth_id: $oauth_id,
					oauth_provider: $oauth_provider
				) {
					user_id
					access_token
				}
			}
		`
	}
};

export default request;
