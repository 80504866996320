import gql from 'graphql-tag';

const request = {
	validation: null,

	properties: {
		name: 'void'
	},

	gql: {
		schema: gql`
			query {
			  request {
					row_id
			  }
			}
		`
	}
};

export default request;
