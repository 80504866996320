import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'user>update'
	},

	validation: {
		notification_email: {
			required: false,
			validator: null,

			// on form state init (useForm)
			falseIfEmpty: true
		},
		notification_sms: {
			required: false,
			validator: null,

			// on form state init (useForm)
			falseIfEmpty: true
		},

		notification_webpush: {
			required: false,
			validator: null,

			// on form state init (useForm)
			falseIfEmpty: true
		}
	},

	gql: {
		schema: gql`
			mutation UpdateUser(
				$notification_email: Boolean,
			  $notification_sms: Boolean,
			  $notification_webpush: Boolean
			) {
				request(
					notification_email: $notification_email,
			    notification_sms: $notification_sms,
			    notification_webpush: $notification_webpush
				) {
					user_id
				}
			}
		`
	}
};

export default request;
