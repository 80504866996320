import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'contact>mutate'
	},

	// Person
	validation: {
		givenname: {
			required: true,
			validator: null
		},

		surname: {
			required: true,
			validator: null
		},

		postal_address: {
			required: false,
			validator: null
		},

		postal_postal_code: {
			required: false,
			validator: null
		},

		/*
		postal_city_nm: {
			required: true,
			validator: null
		},
		*/

		email: {
			required: true,
			validator: {
				regEx: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
				// error: 'Invalid email'
				tId: 'validation_error_email'
			}
		},

		telephone: {
			required: false,
			validator: null
		},

		// Organisation
		contact_type_id: {
			required: false,
			validator: null
		},

		organisation_nm: {
			required: false,
			validator: null
		},

		organisation_tax_no: {
			required: false,
			validator: null
		},

		organisation_taxable: {
			required: false,
			validator: null,
			
			// on form state init (useForm)
			falseIfEmpty: true
		},

		postal_organisation_address: {
			required: false,
			validator: null
		},

		postal_organisation_postal_code: {
			required: false,
			validator: null
		},

		postal_organisation_city_nm: {
			required: false,
			validator: null
		},

		email_organisation: {
			required: false,
			validator: {
				regEx: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
				// error: 'Invalid email'
				tId: 'validation_error_email'
			}
		},

		telephone_organisation: {
			required: false,
			validator: null
		},

		http_organisation: {
			required: false,
			validator: null
		},

		agreement_contact: {
			required: false,
			validator: null,

			// on form state init (useForm)
			falseIfEmpty: true
		},

		agreement_gdpr: {
			required: false,
			validator: null,

			// on form state init (useForm)
			falseIfEmpty: true
		},

		agreement_terms: {
			required: false,
			validator: null,

			// on form state init (useForm)
			falseIfEmpty: true
		}
	},

	gql: {
		schema: gql`
			mutation Contact(
		    $contact_id: Int,
		    $contact_type_id: String,
		    $organisation_nm: String,
		    $organisation_type: String,
		    $organisation_taxable: Boolean,
		    $organisation_tax_no: String,
		    $givenname: String,
		    $surname: String,
		    $birth_dt: String,
		    $email: String,
		    $email_organisation: String,
		    $telephone: String,
		    $telephone_organisation: String,
		    $postal_address: String,
		    $postal_organisation_address: String,
		    $postal_city_nm: String,
		    $postal_organisation_city_nm: String,
		    $postal_postal_code: String,
		    $postal_organisation_postal_code: String
		    $postal_country_iso3: String,
		    $postal_organisation_country_iso3: String
		    $http_organisation: String,
		    $agreement_gdpr: Boolean,
		    $agreement_terms: Boolean,
		    $agreement_contact: Boolean,
		  ) {
		    request(
		      contact_id: $contact_id,
		      contact_type_id: $contact_type_id,
		      organisation_nm: $organisation_nm,
		      organisation_type: $organisation_type,
		      organisation_taxable: $organisation_taxable,
		      organisation_tax_no: $organisation_tax_no,
		      givenname: $givenname,
		      surname: $surname,
		      birth_dt: $birth_dt,
		      email: $email,
		      email_organisation: $email_organisation,
		      telephone: $telephone,
		      telephone_organisation: $telephone_organisation,
		      postal_address: $postal_address,
		      postal_organisation_address: $postal_organisation_address,
		      postal_city_nm: $postal_city_nm,
		      postal_organisation_city_nm: $postal_organisation_city_nm,
		      postal_postal_code: $postal_postal_code,
		      postal_organisation_postal_code: $postal_organisation_postal_code
		      postal_country_iso3: $postal_country_iso3,
		      postal_organisation_country_iso3: $postal_organisation_country_iso3
		      http_organisation: $http_organisation,
		      agreement_gdpr: $agreement_gdpr,
		      agreement_terms: $agreement_terms,
		      agreement_contact: $agreement_contact
		    ) {
		      contact_id
		      givenname
		      surname
		      organisation_nm
		      email
		      telephone
		    }
		  }
		`
	}
};

export default request;
